import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="bg-light py-4">
      <div className="container px-3 pb-4" style={{ maxWidth: 750 }}>
        <div className="card shadow-sm">
          <div className="card-body">
            <h1>NOT FOUND</h1>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </div>
    </div>

  </Layout>
)

export default NotFoundPage
